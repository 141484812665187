import React, { Fragment } from "react"
import Layout from "../components/layout"

const ContactThankYouPage = () => (
  <Fragment>
    <div>
      <h1>Thank you for reaching out to us!</h1>
      <p>We will get back to you as soon as possible.</p>
    </div>
  </Fragment>
)

export default () => (
  <Layout>
    <section className="free-trial-area pb-100">
      <div className="container">
        <div className="free-trial-content">
          <ContactThankYouPage />
        </div>
      </div>
    </section>
  </Layout>
)
